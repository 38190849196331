<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <!--b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2>
      </b-link-->
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
              fluid
              :src="imgUrl"
              alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
        >

          <template v-if="showVerification">

            <b-card-title
                title-tag="h2"
                class="font-weight-bold mb-1"
            >
              Verificar su cuenta
            </b-card-title>

            <b-card-text class="mb-2">
              Por favor, ingrese el código de verificación enviado a su dirección de correo electrónico para completar su proceso de inicio de sesión
            </b-card-text>

            <div>
              <label>Código</label>
              <div class="d-flex justify-content-between mt-1 mb-2">
                <b-form-input
                    v-model="code"
                    class="form-control-lg font-weight-bold text-center"
                    maxlength="6"
                />
              </div>
            </div>

            <!-- submit buttons -->
            <b-button
                variant="primary"
                block
                @click="handleVerify"
                :disabled="isBusy"
            >
              <template v-if="isBusy">
                <b-spinner small />
                Verificando...
              </template>
              <template v-else>
                Verificar
              </template>
            </b-button>
            <b-button
                variant="link"
                block
                @click="handleBack"
                :disabled="isBusy"
            >
              Regresar
            </b-button>



          </template>

          <template v-else>

            <b-img
                fluid
                :src="logoUrl"
                alt="Logo slogan"
                class="mb-3"
            />

            <b-card-title
                title-tag="h2"
                class="font-weight-bold mb-1"
            >
              Bienvenido a SGA
            </b-card-title>

            <b-card-text class="mb-2">
              Por favor, inicie sesión en su cuenta para acceder al sistema
            </b-card-text>

            <!-- form -->

            <b-form
                class="auth-login-form mt-2"
                @submit.prevent
            >
              <!-- email -->
              <b-form-group
                  label="Correo electrónico"
                  label-for="login-email"
              >
                <b-form-input
                    id="login-email"
                    v-model="email"
                    name="login-email"
                    placeholder="usuario@correo.com"
                />
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Contraseña</label>
                </div>
                <b-input-group
                    class="input-group-merge"
                >
                  <b-form-input
                      id="login-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                  variant="primary"
                  block
                  @click="handleLogin"
                  :disabled="isBusy"
              >
                <template v-if="isBusy">
                  <b-spinner small />
                  Iniciando...
                </template>
                <template v-else>
                  Iniciar sesión
                </template>
              </b-button>

              <b-form-group class="text-center mt-2" >
                <b-link :to="{name:'auth-forgot-password'}">
                  <small>¿Olvidaste tu contraseña?</small>
                </b-link>
              </b-form-group>




            </b-form>

          </template>



          <validation-errors v-if="errors" :errors="errors" class="mt-2"/>

        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  BSpinner
} from 'bootstrap-vue'
import store from '@/store/index'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { login2FA, verifyCode } from '@/modules/auth/api'
import { getHomeRouteForLoggedInUser, resolveUserRoleFull, resolveUserRole } from '@/modules/auth/utils'
import ValidationErrors from '@/modules/components/ValidationErrors.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    ValidationErrors
  },

  mixins: [togglePasswordVisibility],

  data() {
    return {

      errors: null,
      isBusy: false,

      showVerification: false,
      code: null,
      uuid: null,


      status: '',
      password: '',
      email: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      logoSlogan: require('@/assets/images/logo/logo-slogan.png')
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

    logoUrl() {

      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.logoSlogan = require('@/assets/images/logo/logo-slogan-dark.png')
        return this.logoSlogan
      }

      return this.logoSlogan
    },

    imgUrl() {

      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }

      return this.sideImg
    },
  },
  methods: {

    async handleLogin() {

      try {

        this.isBusy = true
        this.errors = []
        this.uuid = null

        const credentials = {
          'email': this.email,
          'password': this.password
        }


        const data  = await login2FA( credentials )

        this.uuid = data.uuid

        this.showVerification = true


      }catch ( error ) {


        if ( error.response  ){
          // se ha hecho la peticion al servidor y se produjo un error


          // errores de validacion
          if ( error.response.status == 422 ) {

            console.log("error 422", error.response.data )
              // errores de validacion
              this.errors = error.response.data

            console.log(this.errors)

          }


          // errores de autenticacion 401
          //Has excedido el número máximo de intentos de inicio de sesión 429
          // Tu cuenta ha sido bloqueada. Por favor, contáctanos para obtener ayuda. 403
          if ( error.response.status === 401 || error.response.status === 429 || error.response.status === 403 ) {


            const  data  = error.response.data
            const { reset_token: resetToken } = data
            console.log("error 401", resetToken)

            if ( resetToken ){

              localStorage.removeItem('reset_token');
              localStorage.setItem('reset_token', resetToken);
              this.$router.push({ name: 'auth-reset' });
              return


            }

            // errores de validacion
            this.errors = data

          }

          //


        } else {
          // peticion no llegó al servidor

        }

      } finally {
        this.isBusy = false
      }

    },

    async handleVerify(){

      try {

        this.isBusy = true
        this.errors = []

        const codeString = this.code

        const params = {
          uuid: this.uuid,
          code: codeString
        }

        const data = await verifyCode( params )

        // guardar en local storage info user
        const { access_token, user } = data

        const userData = {
          id: user.id,
          fullName: `${user.usrNombres} ${user.usrApellidos}`,
          username: user.name,
          avatar: require('@/assets/images/avatars/00-small.png'),
          email: user.email,
          type: user.usrType,
          role: resolveUserRole(user.usrType),
          fullRole: resolveUserRoleFull(user.usrType),
          ability: [
            {
              action: 'manage',
              subject: 'all',
            },
          ],
          extras: {}
        }


        // almacenamiento local
        localStorage.setItem('userData', JSON.stringify(userData))
        localStorage.setItem('accessToken', access_token)
        localStorage.setItem('refreshToken', access_token)
        this.$ability.update(userData.ability)


        // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
        this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Bienvenido ${userData.fullName || userData.username}`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `Has iniciado sesión como "${userData.fullRole}"`,
              },
            })
          })

      } catch ( error ) {

        if ( error.response  ){
          // se ha hecho la peticion al servidor y se produjo un error


          // errores de validacion
          if ( error.response.status === 422 ) {

            console.log("error 422", error.response.data )
            // errores de validacion
            this.errors = error.response.data

            console.log(this.errors)

          }

          // errores de autenticacion 401
          //Has excedido el número máximo de intentos de inicio de sesión 429
          // Tu cuenta ha sido bloqueada. Por favor, contáctanos para obtener ayuda. 403
          if ( error.response.status === 401 || error.response.status === 429 || error.response.status === 403 ) {
            console.log("errors ***", error.response)
            // errores de validacion
            this.errors = error.response.data
          }



        } else {
          // peticion no llegó al servidor

        }

      } finally {
        this.isBusy = false
      }

    },

    async handleBack(){

      this.errors = []
      this.uuid = null
      this.email = ''
      this.password = ''

      this.showVerification = false

    },


  },
  created() {
  },
  mounted() {
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
